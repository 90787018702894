<template>
  <div>
    <v-card>
      <v-card-text>
        <h3 class="headline">ເພີ່ມ Promotion</h3>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols>
                <v-text-field
                    label="Promotion Code"
                    v-model="promotion.pro_code"
                    outlined
                    dense
                ></v-text-field>
                <p class="error--text">{{server_errors.pro_code}}</p>
              </v-col>
              <v-col cols>
                <v-text-field
                    label="Promotion name"
                    v-model="promotion.name"
                    outlined
                    dense
                ></v-text-field>
                <p class="error--text">{{server_errors.name}}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols>
                <v-checkbox v-model="specific"
                            label="ໃຊ້ໄດ້ສະເພາະລູກຄ້າທີ່ເຈາະຈົງ"
                            row>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols>
                <v-checkbox v-model="limit_min_price"
                            label="ລາຄາຊື້ຂັ້ນຕ່ຳ"
                            row>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="limit_min_price">
              <v-col>
                <v-text-field
                    label="ຂັ້ນຕຳ"
                    v-model="promotion.buy_min_price"
                    type="number"
                    outlined
                    dense
                ></v-text-field>
                <p class="error--text">{{server_errors.buy_min_price}}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols>
                <v-checkbox v-model="limit_use"
                            label="ຈຳກັດການໃຊ້ຕໍ່ User"
                            row>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="limit_use">
              <v-col cols>
                <v-text-field
                    label="ຈຳນວນຄັ້ງ"
                    v-model="promotion.amount_use"
                    type="number"
                    outlined
                    dense
                ></v-text-field>
                <p class="error--text">{{server_errors.amount_use}}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-select
                    required
                    v-model="selectedProType"
                    :items="promotionType"
                    item-text="name"
                    item-value="id"
                    label="ປະເພດສ່ວນຫຼຸດ *"
                    outlined
                    dense
                >
                </v-select>
                <p class="error--text">{{server_errors.amount_use}}</p>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="ປະລິມານສ່ວນຫຼູດ"
                    v-model="promotion.volume"
                    type="number"
                    outlined
                    dense
                ></v-text-field>
                <p class="error--text">{{server_errors.volume}}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols>
                  <v-menu
                      v-model="expired_menu"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="expired_date"
                          label="ວັນໝົດອາຍຸ"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="expired_date"
                    ></v-date-picker>
                  </v-menu>
                <p class="error--text">{{server_errors.expired_date}}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Description"
                    v-model="promotion.description"
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="Close()">
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="Save()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import manage from "@/mixins/platform/manage_promotion";

export default {
  mixins: [manage],
};
</script>

<style>
.primary-color {
  color: #00c1d2;
}

@import "../../../../public/scss/main.scss";
</style>