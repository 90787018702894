//import Validate from "@/plugins/Validation/Branch/branch"
export default {
    data() {
        return {
            loading: false,
            TableLoading: false,
            expired_date: '',
            // expired_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            //     .toISOString()
            //     .substr(0, 10),
            expired_menu: false,
            promotion: {},
            limit_min_price: 1,
            limit_use: 0,
            specific: 0,
            promotions: [],

            selectedProType: 'money',
            promotionType: [
                {
                    id: 'water',
                    name: 'ແຖມຕຸກນ້ຳ'
                },
                {
                    id: 'money',
                    name: 'ຈຳນວນເງິນ'
                },
                {
                    id: 'percent',
                    name: 'ສ່ວນຫຼຸດເປີເຊັນ'
                }
            ],
            selectedEditRole: '',
            selectedTruck: '',
            dataID: '',
            server_errors: {
                pro_code: '',
                description: '',
                name: '',expired_date:'',
                buy_min_price:'',amount_use:''
            },
            news: {},

            image: "",
            imageUrl: "",


            //Pagination
            offset: 15,
            pagination: {},
            per_page: 12,
            headers: [
                {
                    text: "ລະຫັດສ່ວນຫຼູດ", align: "center", sortable: false, value: "pro_code", width: "150px"
                },
                {
                    text: "ຊື່ໂປຣໂມຊັ້ນ", align: "start", sortable: false, value: "name", width: "150px"
                },
                {
                    text: "ໃຊ້ໄດ້", align: "start", sortable: false, value: "specific", width: "150px"
                },
                {
                    text: "Description", align: "start", sortable: false, value: "description", width: "150px"
                },
                {text: "ປະເພດໂປຣ", value: "pro_type", width: "150px"},
                {text: "ສ່ວນຫຼຸດ", value: "volume", width: "150px"},
                {text: "ວັນໝົດອາຍຸ", value: "expired_date", width: "150px"},
                {text: "ຊື້ຂັ້ນຕຳ່", value: "limit_min_price", width: "100px"},
                {text: "ຂັ້ນຕຳ່", value: "buy_min_price", width: "150px"},
                {text: "ຈຳກັດການໃຊ້", value: "limit_use", width: "150px"},
                {text: "ລູກຄ້າໃຊ້ໄດ້", value: "amount_use", align: "center", width: "150px"},
                {text: "ສະຖານະ", value: "status", width: "130px"},
                {text: "Created", value: "created_at", width: "150px"},
                {text: "Actions", value: "actions", sortable: false},
            ],
            toast: {
                value: true, color: 'success', msg: 'Success'
            },
            toast_error: {
                value: true, color: 'error', msg: 'Something when wrong!'
            }
        }
    },
    methods: {
        fetchData() {
            this.TableLoading = true;
            this.$admin.get('promotions',
                {
                    params: {
                        page: this.pagination.current_page,
                        per_page: this.per_page,
                    }
                }).then(res => {
                this.promotions = res.data.data.data;
                this.pagination = res.data.data;
                this.TableLoading = false;
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });
        },
        Save() {
            if (this.$refs.form.validate() == true) {
                this.Submit();
            }
        },
        Submit() {
            const data = {
                pro_code: this.promotion.pro_code,
                name: this.promotion.name,
                description: this.promotion.description,
                limit_min_price: this.limit_min_price,
                buy_min_price: this.promotion.buy_min_price,
                limit_use: this.limit_use,
                amount_use: this.promotion.amount_use,
                pro_type: this.selectedProType,
                volume: this.promotion.volume,
                expired_date: this.expired_date,
                specific: this.specific,
            };

            this.$admin.post('/promotion/add', data)
                .then(res => {
                    if (res.status == 200) {
                        setTimeout(() => {
                            this.loading = false;
                            this.promotion = {},
                                this.$router.go(-1);
                        }, 300);
                    }
                }).catch(error => {
                this.loading = false;
                if (error.response.status == 422) {
                    const obj = error.response.data.errors;
                    for (let [key, user] of Object.entries(obj)) {
                        this.server_errors[key] = user[0];
                    }
                    this.$store.commit("Toast_State", this.toast_error);
                }
            });
        },

        updatePromotion(id) {
            this.$router.push({name: 'UpdatePromotion', params: {id: id}});
        },

        createPromotion() {
            this.$router.push({name: 'CreatePromotion'});
        },
        Close() {
            this.$router.go(-1);
        },
        deleteItem(id) {
            this.dataID = id;
            this.$store.commit("modalDelete_State", true);
        },
        deleteItemConfirm() {
            this.loading = true;
            this.$admin.delete('promotion/delete/' + this.dataID).then(res => {
                if (res.status == 200) {
                    setTimeout(() => {
                        this.fetchData();
                        this.$store.commit("modalDelete_State", false);
                        this.loading = false;
                        this.$store.commit("Toast_State", this.toast);
                    }, 300);
                }
            }).catch(() => {
                this.$store.commit("modalDelete_State", false);
                this.loading = false;
            })
        },
        reset() {
            this.$refs.form.reset();
        },
        showStatus(status){
            if(status == 'open'){
                return 'success'
            } else if(status == 'close'){
                return 'error'
            }
        },
        showMinPrice(min_price){
            if(min_price == 0){
                return 'ບໍ່ມີຂັ້ນຕ່ຳ'
            } else if(min_price == 1){
                return 'ມີຂັ້ນຕ່ຳ'
            }
        },
        limitUse(limit_use){
            if(limit_use == 0){
                return 'ບໍ່ຈຳກັດ'
            } else if(limit_use == 1){
                return 'ຈຳກັດ'
            }
        } ,
        showPromotiontype(type){
            if(type == 'water'){
                return 'ສ່ວນຫຼຸດເປັນນ້ຳດື່ມ'
            } else if(type == 'percent'){
                return 'ສ່ວນຫຼຸເປັນເປີເຊັນ'
            } else if(type == 'money'){
                return 'ສ່ວນຫຼຸດເປັນງິນ'
            }
        },
        showSpecific(type) {
            if (type == 0) {
                return 'ໃຊ້ໄດ້ທົ່ວໄປ'
            } else if (type == 1) {
                return 'ສະເພາະເຈາະຈົງ'
            }
        }
    },
    watch:{
        'specific':function (value){
            if(value == false){
                this.specific = 0;
            } else if(value == true){
                this.specific = 1;
            }
        },
         'limit_min_price':function (value){
             if(value == false){
                 this.buy_min_price = '';
                 this.limit_min_price = 0;
             } else if(value == true){
                 this.limit_min_price = 1;
             }
         },
        'limit_use':function (value){
            if(value == false){
                this.amount_use = '';
                this.limit_use = 0;
            } else if(value == true){
                this.limit_use = 1;
            }
        },
        'promotion.pro_code': function () {
            this.server_errors.pro_code = '';
        },
        'promotion.name': function () {
            this.server_errors.name = '';
        },
        'promotion.expired_date': function () {
            this.server_errors.expired_date = '';
        },
        'promotion.volume': function () {
            this.server_errors.volume = '';
        },
        'promotion.buy_min_price': function () {
            this.server_errors.buy_min_price = '';
        },
    },
    created() {
        this.fetchData();
    },
}
